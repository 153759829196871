import React, { useState, useEffect } from 'react';
import { usePopup } from '../../../../Popups/PopupContext.js';
import Icons from '../../../../Common/IconsComponent.jsx';
import ProjectTasksItem from './ProjectTasksItem';

const ProjectTasks = ({ projectId }) => {
	const { hidePopup, user } = usePopup();
	const [tasks, setTasks] = useState([]);

	useEffect(() => {
		const projectsData = JSON.parse(localStorage.getItem('projectsData')) || [];

		const extractedTasks = projectsData.flatMap((project) =>
			project.active_tasks
				.filter((task) => project.id === projectId)
				.map((task) => ({
					taskId: task.id,
					name: task.name,
					link: task.link,
					bonus: task.reward,
					status: user?.projects_tasks?.some(
						(projTask) => projTask.projects_task_id === task.id
					),
				}))
		);
		const sortedTasks = extractedTasks.sort((a, b) => {
			if (a.status === false && b.status !== false) return -1;
			if (b.status === false && a.status !== false) return 1;
			return 0;
		});

		setTasks(sortedTasks);
	}, [projectId]);

	const сloseModal = () => {
		hidePopup();
	};

	const getLiCalssName = (index, status) => {
		let classname = '';
		if (index >= 5)
			if (status) classname = 'hidden';
			else classname = 'waiting';
		return 'tasks__item ' + classname;
	};

	return (
		<div className='main__tasks tasks'>
			<div className='modal__close' onClick={сloseModal}>
				<Icons.Close />
			</div>
			<ul className='tasks__list'>
				{tasks.map((task, index) => (
					<li key={index} className={getLiCalssName(index, task.status)}>
						<ProjectTasksItem task={task} />
					</li>
				))}
			</ul>
		</div>
	);
};

export default ProjectTasks;
