import React, { useState, useEffect } from 'react';
import Icons from '../../../Common/IconsComponent';
import { usePopup } from '../../../Popups/PopupContext.js';
import TaskListItem from './TaskListItem';
import useFetchMainTasks from '../../../../helpers/fetchMainTasks';

const TaskList = () => {
	const { hidePopup, user } = usePopup();
	const { tasksData, fetchMainTasks, tasksLoading, tasksError } = useFetchMainTasks();
	const [tasks, setTasks] = useState([]);
	const [countOpenedTasks, setCountOpenedTasks] = useState(0);

	useEffect(() => {
		fetchMainTasks(user?.id_telegram);
	}, []);

	useEffect(() => {
		if (!tasksLoading && tasksData) {
			const taskList = tasksData.map((task, index) => {
				return {
					id: task?.id,
					name: task?.name,
					link: task?.link,
					code: task?.code,
					bonus: task?.reward,
					status: task.status,
				};
			});
			const sortedTasks = taskList.sort((a, b) => {
				if (a.status === 0 && b.status !== 0) return -1;
				if (b.status === 0 && a.status !== 0) return 1;
				return 0;
			});
			setTasks(sortedTasks);

			const openedTasks = taskList.filter((task) => task.status === 0);
			setCountOpenedTasks(openedTasks.length);
		}
	}, [tasksLoading, tasksData]);

	const сloseModal = () => {
		hidePopup();
	};

	const getLiCalssName = (index, status) => {
		let classname = '';
		if (index >= 5)
			if (status === 1) classname = 'hidden';
			else classname = 'waiting';
		return 'tasks__item ' + classname;
	};

	return (
		<div className='main__tasks tasks'>
			<div className='modal__close' onClick={сloseModal}>
				<Icons.Close />
			</div>
			<ul className='tasks__list'>
				{tasks.map((task, index) => (
					<li key={index} className={getLiCalssName(index, task.status)}>
						<TaskListItem task={task} />
					</li>
				))}
			</ul>
		</div>
	);
};

export default TaskList;
