import styled from 'styled-components';

const MyBalance = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 100;
	span {
		font-size: 24px;
		text-align: center;
		font-family: 'Kumbh';
		@media (max-height: 685px) {
			font-size: 18px;
		}
	}
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 54px;

		svg,
		img {
			margin-left: 8px;
			width: 31px;
			height: 31px;
		}

		@media (max-height: 685px) {
			font-size: 42px;
			margin: 2px 0 16px 0;

			svg,
			img {
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export default MyBalance;
