import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import heroAnimation from '../../../assets/animations/potato.riv';
import tapAnimation from '../../../assets/animations/$hit_tap.riv';
import { usePopup } from '../../Popups/PopupContext.js';
import useBoost from '../../../helpers/clickerBoostHelper';
import boostIcon from '../../../assets/img/shitBoost.webp';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetSessionPointMutation } from '../../../services/phpService';

const HeroComponent = () => {
	const {
		user,
		addNotification,
		heroState,
		setHeroState,
		setHeroBackground,
		heroBackground,
		genHash,
		setUserDataPollingDisabled,
	} = usePopup();
	const [getSessionPoint] = useGetSessionPointMutation();
	const [currEnergy, setCurrEnergy] = useState(0);
	const [gamePaused, setGamePaused] = useState(false);
	const [clickerActive, setClickerActive] = useState(false);
	const [animations, setAnimations] = useState([]);
	const [isAnimationActive, setIsAnimationActive] = useState(false);
	let [energyVal, setEnergyVal] = useState(user?.tap_value ?? 1);
	let [clickNewCoins, setClickNewCoins] = useState(user?.tap_value ?? 1);
	const { boostPhase, visible, position, activateBoost } = useBoost(gamePaused);
	const sessionClaimed = useRef(false);

	const { rive, RiveComponent } = useRive({
		src: heroAnimation,
		stateMachines: 'State Machine 1',
		autoplay: true,
		onInit: (riveInstance) => {
			meditation1Input && (meditation1Input.value = false);
			meditation2Input && (meditation2Input.value = false);
			boostInput && (boostInput.value = false);
			tapInput && (tapInput.value = false);
		},
	});

	const { rive: tapRive, RiveComponent: TapRiveComponent } = useRive({
		src: tapAnimation,
		stateMachines: 'State Machine 1',
		autoplay: true,
		onInit: (riveInstance) => {
			tapIdle && (tapIdle.value = true);
			tapActive && (tapActive.value = false);
		},
	});

	const meditation1Input = useStateMachineInput(rive, 'State Machine 1', 'meditation1');
	const meditation2Input = useStateMachineInput(rive, 'State Machine 1', 'meditation2');
	const boostInput = useStateMachineInput(rive, 'State Machine 1', 'boost');
	const tapInput = useStateMachineInput(rive, 'State Machine 1', 'tap');
	const tapIdle = useStateMachineInput(tapRive, 'State Machine 1', 'idle');
	const tapActive = useStateMachineInput(tapRive, 'State Machine 1', 'tap');

	useEffect(() => {
		const storedEnergy = localStorage.getItem('shitClickerProgress');
		if (storedEnergy) {
			setCurrEnergy(Number(storedEnergy));
		}
	}, []);

	useEffect(() => {
		if (!meditation1Input || !meditation2Input || !boostInput || !tapInput) {
			return;
		}

		switch (heroState) {
			case 'claim_now':
				activateAnimation(meditation2Input);
				setHeroBackground('yellow');
				setClickerActive(false);
				break;

			case 'boost':
				activateAnimation(boostInput);
				break;

			case 'cool_down':
				activateAnimation(tapInput);
				setHeroBackground('gray');
				setClickerActive(true);
				break;

			case 'meditate':
				activateAnimation(meditation1Input);
				setGamePaused(true);
				break;

			default:
				break;
		}
	}, [
		heroState,
		heroBackground,
		meditation1Input,
		meditation2Input,
		boostInput,
		tapInput,
	]);

	useEffect(() => {
		if (boostPhase === true) {
			setHeroState('boost');
		} else {
			setHeroState('cool_down');
		}
	}, [boostPhase]);

	const sessionClaimer = async () => {
		if (currEnergy >= (user?.max_energy ?? 1000) && !sessionClaimed.current) {
			sessionClaimed.current = true;
			try {
				await getSessionPoint({
					token: await genHash(),
					id_telegram: user?.id_telegram,
				}).unwrap();
			} catch (e) {
				console.log('Error submitting points:', e);
			}
			addNotification('success', 'Got free $hit, tap again once meditation is over!');
			setCurrEnergy(0);
		}
	};

	useEffect(() => {
		localStorage.setItem('shitClickerProgress', currEnergy);
		if (currEnergy >= (user?.max_energy ?? 1000) && !sessionClaimed.current) {
			setGamePaused(true);
			setUserDataPollingDisabled(false);
			sessionClaimer();
			setHeroState('meditate');
			setTimeout(() => {
				if (heroState !== 'claim_now') {
					setHeroState('cool_down');
					setGamePaused(false);
					sessionClaimed.current = false;
				}
			}, 8000);
		}
	}, [currEnergy, heroState]);

	const activateAnimation = (input) => {
		setInputFalse(meditation1Input);
		setInputFalse(meditation2Input);
		setInputFalse(boostInput);
		setInputFalse(tapInput);

		if (input) {
			input.value = true;
		}
	};

	const setInputFalse = (input) => {
		if (input) {
			input.value = false;
		}
	};

	const updateCurrCoins = () => {
		return clickNewCoins;
	};

	const handleShowAnimation = useCallback((event) => {
		if (!event) return;

		const touch = event.touches ? event.touches[0] : event;
		const x = touch.pageX - 90;
		const y = touch.pageY - 70;

		const id = Date.now();
		setAnimations((prev) => [...prev, { id, x, y }]);
		setIsAnimationActive(true);
	}, []);

	const handleTouchStart = (event) => handleShowAnimation(event);

	const handleTouchEnd = () => {
		if (tapInput) {
			tapInput.fire();
		}
		const clickNewCoins = updateCurrCoins();
		setCurrEnergy((prevEnergy) =>
			Math.min(prevEnergy + energyVal, user?.max_energy ?? 1000)
		);
	};

	const removeAnimation = useCallback((id) => {
		setAnimations((prev) => prev.filter((anim) => anim.id !== id));
	}, []);

	const calculateStrokeDasharray = (currEnergy) => {
		const circleCircumference = 2 * Math.PI * 45;
		const percentage = (currEnergy / user?.max_energy ?? 1000) * circleCircumference;
		return `${percentage} ${circleCircumference}`;
	};

	const boostClickedHandler = () => {
		activateBoost(energyVal, clickNewCoins, setEnergyVal, setClickNewCoins, user);
	};

	const isSmallScreen = window.innerHeight <= 685;

	const progressBoxStyle = {
		position: 'absolute',
		height: isSmallScreen ? '108%' : '108%',
		width: isSmallScreen ? '108%' : '108%',
	};

	const riveTapStyle = {
		position: 'absolute',
		scale: '1.75',
	};

	const riveComponentStyle = {
		maxHeight: isSmallScreen ? '215px' : '265px',
		maxWidth: isSmallScreen ? '215px' : '265px',
	};

	const yValues = isSmallScreen ? [-50, -120] : [-150, -230];
	const xValues = isSmallScreen ? [-10, -10] : [15, 15];

	return (
		<>
			<div
				className={`main__image animate__animated animate__fadeIn ${
					heroBackground === 'gray' ? 'main__image_gray' : 'main__image_yellow'
				}`}
				onTouchStart={
					heroState !== 'cool_down' && heroState !== 'boost' ? null : handleTouchStart
				}
				onTouchEnd={
					heroState !== 'cool_down' && heroState !== 'boost' ? null : handleTouchEnd
				}
			>
				{clickerActive === true && (
					<>
						<AnimatePresence>
							{isAnimationActive &&
								animations.map((anim) => (
									<motion.div
										key={anim.id}
										className='clickerAnimation'
										initial={{ opacity: 1, y: 0 }}
										animate={{ opacity: [1, 0], x: xValues, y: yValues }}
										exit={{ opacity: 0 }}
										transition={{ duration: 0.5 }}
										style={{
											color: '#000',
											fontSize: '52px',
											left: `${anim.x}px`,
											top: `${anim.y}px`,
											position: 'absolute',
											color: '#333333',
											zIndex: 10,
										}}
										onAnimationComplete={() => removeAnimation(anim.id)}
									>
										<div className='clicker__clickValue'></div>+{clickNewCoins}
									</motion.div>
								))}
						</AnimatePresence>
						{!gamePaused && visible ? (
							<motion.div
								initial={{
									y: 7,
									rotate: 0,
									opacity: 1,
								}}
								animate={{
									y: [5, 15, 20],
									x: [-15, 15, 5],
								}}
								transition={{
									duration: 4,
									repeat: Infinity,
									repeatType: 'mirror',
									ease: 'easeInOut',
								}}
								style={{
									position: 'absolute',
									top: '50%',
									left: 0,
									zIndex: 250,
								}}
							>
								<motion.div
									animate={{
										opacity: [0, 1],
									}}
									transition={{
										duration: 4,
										repeat: Infinity,
										repeatType: 'mirror',
										ease: 'easeInOut',
									}}
								>
									<div
										className='boost-element'
										style={{
											position: 'absolute',
											left: `${position.x}px`,
											top: `${position.y}px`,
											cursor: 'pointer',
											width: '100px',
											height: '100px',
											zIndex: 250,
										}}
										onClick={boostClickedHandler}
									>
										<motion.img
											src={boostIcon}
											alt='Boost'
											style={{
												width: '100%',
												height: '100%',
												userSelect: 'none',
												scale: '100%',
											}}
											initial={{ opacity: 0, rotate: 0 }}
											animate={{ opacity: 1, rotate: 0 }}
											transition={{
												duration: 4,
												repeat: Infinity,
												repeatType: 'mirror',
												ease: 'easeInOut',
											}}
										/>
									</div>
								</motion.div>
							</motion.div>
						) : null}
						<div className='clicker__progressBox' style={progressBoxStyle}>
							<div className='clicker__progressBar'>
								<svg
									viewBox='0 0 100 100'
									style={{
										position: 'absolute',
										width: '100%',
										height: '100%',
										borderRadius: '100%',
									}}
								>
									<defs>
										<filter id='boxShadow' x='-20%' y='-20%' width='140%' height='140%'>
											<feGaussianBlur in='SourceAlpha' stdDeviation='3' />
											<feOffset dx='0' dy='0' result='offsetblur' />
											<feComponentTransfer>
												<feFuncA type='linear' slope='0.5' />
											</feComponentTransfer>
											<feMerge>
												<feMergeNode />
												<feMergeNode in='SourceGraphic' />
											</feMerge>
										</filter>
									</defs>

									<circle
										cx='50'
										cy='50'
										r='45'
										fill='none'
										stroke='none'
										strokeWidth='2.5'
									></circle>

									{currEnergy > 0 && (
										<circle
											cx='50'
											cy='50'
											r='45'
											fill='none'
											stroke='#000'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeDasharray={calculateStrokeDasharray(currEnergy)}
											style={{ transition: 'stroke-dasharray 0.35s' }}
										></circle>
									)}
								</svg>
							</div>
						</div>
					</>
				)}
				{/* {(heroState === 'cool_down' || heroState === 'boost') && <TapRiveComponent style={riveTapStyle} />} */}
				<RiveComponent style={riveComponentStyle} />
			</div>
		</>
	);
};

export default HeroComponent;
