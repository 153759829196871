import React, { useState, useEffect, useRef } from 'react';
import NavigationWidget from '../NavigationWidget';
import { MyTitle } from '../../../Common/styles';
import { useNavigate } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import Preloader from '../Preloader/Preloader';
import {
	useGetActivityMutation,
	useGetEventsMutation,
} from '../../../../services/phpService';
import './Activity.scss';

const ActivityScreen = () => {
	const [getActivity] = useGetActivityMutation();
	const [getEvents] = useGetEventsMutation();
	const [activityData, setActivityData] = useState([]);
	const [eventsData, setEventsData] = useState([]);
	const [activityLoading, setActivityLoading] = useState(true);
	const [eventsLoading, setEventsLoading] = useState(true);
	const navigate = useNavigate();
	const containerRef = useRef(null);
	const [containerWidth, setContainerWidth] = useState(0);
	const [loading, setLoading] = useState(true);
	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	useEffect(() => {
		const fetchActivities = async () => {
			try {
				const res = await getActivity().unwrap();
				setActivityData(res);
			} catch (error) {
				console.error(error);
			} finally {
				setActivityLoading(false);
			}
		};
		fetchActivities();
	}, [getActivity]);

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				const res = await getEvents().unwrap();
				setEventsData(res);
			} catch (error) {
				console.error(error);
			} finally {
				setEventsLoading(false);
			}
		};
		fetchEvents();
	}, [getEvents]);

	const handleNavigateToProjects = (activityId, categoryProjects, activityName) => {
		localStorage.setItem('activityId', JSON.stringify(activityId));
		localStorage.setItem('categoryProjects', JSON.stringify(categoryProjects));
		localStorage.setItem('activityName', JSON.stringify(activityName));
		navigate('/projects', { state: { activityId, categoryProjects, activityName } });
	};

	const handleNavigateToProject = (projectId, categoryProjects) => {
		localStorage.setItem('categoryProjects', JSON.stringify(categoryProjects));
		navigate(`/projects/${projectId}`, { state: { categoryProjects } });
	};

	const numberOfRects = 6;
	const rects = [];

	for (let i = 0; i < numberOfRects; i++) {
		rects.push(
			<rect key={i} x='0' y={i * 58} rx='16' ry='16' width={containerWidth} height='52' />
		);
	}

	const handleNavigateToEvent = (eventId) => {
		navigate(`/events/${eventId}`);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 750);

		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			{loading ? (
				<Preloader />
			) : (
				<div className='activity'>
					<div className='container' ref={containerRef}>
						<MyTitle>Activity</MyTitle>
						{eventsData.map((event) => (
							<div
								key={event.id}
								className='activity__event-banner'
								onClick={() => handleNavigateToEvent(event.id)}
							>
								<img
									src={`${process.env.REACT_APP_SECRET_IMG}/${event.image}`}
									alt={event.name}
								/>
							</div>
						))}
						<div className='activity__cats'>
							{activityLoading || eventsLoading ? (
								<ContentLoader
									speed={2}
									width={'100%'}
									height={numberOfRects * 58}
									viewBox={`0 0 ${containerWidth} ${numberOfRects * 58}`}
									backgroundColor='#f3f3f3'
									foregroundColor='#ecebeb'
								>
									{rects}
								</ContentLoader>
							) : (
								activityData.map((activity) => (
									<div className='activity__cat' key={activity.id}>
										<div className='activity__cat-item'>
											<span>{activity.name}</span>
											<div className='activity__thumbnails'>
												{activity.projects.slice(0, 4).map((project) => (
													<div className='activity__thumbnail-img' key={project.id}>
														<img
															src={`${imageBaseUrl}${project.image}`}
															alt={`проект ${project.id}`}
															onClick={() =>
																handleNavigateToProject(project.id, activity.projects)
															}
														/>
													</div>
												))}
												{activity.total_projects > 4 && (
													<div className='activity__thumbnail-extra'>
														+{activity.total_projects - 4}
													</div>
												)}
											</div>
										</div>
										<div className='activity__cat-item'>
											<button
												onClick={() =>
													handleNavigateToProjects(activity.id, activity.projects, activity.name)
												}
											>
												view
											</button>
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</div>
			)}
			<NavigationWidget />
		</>
	);
};

export default ActivityScreen;
