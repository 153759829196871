import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const Count = ({ time }) => {
	const [remainingTime, setRemainingTime] = useState({});
	const [showCountdown, setShowCountdown] = useState(false);

	useEffect(() => {
		const updateTimer = () => {
			const currentTime = moment().tz('UTC').unix();
			const timeDiff = time - currentTime;

			if (timeDiff > 0) {
				if (timeDiff <= 72 * 60 * 60) {
					setShowCountdown(true);
					const totalHours = Math.floor(timeDiff / (60 * 60));
					setRemainingTime({
						hours: totalHours,
						minutes: Math.floor((timeDiff / 60) % 60),
						seconds: Math.floor(timeDiff % 60),
					});
				} else {
					setShowCountdown(false);
				}
			} else {
				setRemainingTime(null);
			}
		};

		updateTimer();
		const interval = setInterval(updateTimer, 1000);

		return () => clearInterval(interval);
	}, [time]);

	if (!remainingTime) {
		return <span>Event ended!</span>;
	}

	if (!showCountdown) {
		const eventDate = moment.unix(time).format('DD / MM / YYYY');
		return <span>{eventDate}</span>;
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<div style={styles.timeBox}>
				<div style={styles.label}>hours</div>
				<div style={styles.value}>{String(remainingTime.hours).padStart(2, '0')}</div>
			</div>
			<div style={styles.timeBox}>
				<div style={styles.label}>minutes</div>
				<div style={styles.value}>{String(remainingTime.minutes).padStart(2, '0')}</div>
			</div>
			<div style={styles.timeBox}>
				<div style={styles.label}>seconds</div>
				<div style={styles.value}>{String(remainingTime.seconds).padStart(2, '0')}</div>
			</div>
		</div>
	);
};

const styles = {
	timeBox: {
		backgroundColor: '#fff',
		borderRadius: '5px',
		margin: '0 10px',
		padding: '5px 0',
		textAlign: 'center',
		color: '#000',
	},
	label: {
		fontSize: '18px',
	},
	value: {
		fontSize: '24px',
		fontWeight: 'bold',
	},
};

export default Count;
