import { useState, useCallback } from 'react';
import {
	useGetMainTasksMutation,
} from '../services/phpService';

const useFetchMainTasks = () => {
    const [tasksData, setTasksData] = useState(null);
    const [tasksLoading, setLoading] = useState(true);
    const [tasksError, setError] = useState(null);

    const [getMainTasks] = useGetMainTasksMutation();

    const fetchMainTasks = useCallback(async (userId) => {
        if (!userId) {
            setError('User id was not specified');
            setLoading(false);
        }
        setLoading(true);
        setError(null);
        try {
            const res = await getMainTasks({userId});
            if (res?.data) {
                setTasksData(res.data);
            } else {
                setTasksData(null);
            }
        } catch (err) {
            console.error('Error fetching projects data:', err);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [getMainTasks]);

    return { tasksData, fetchMainTasks, tasksLoading, tasksError };
};

export default useFetchMainTasks;