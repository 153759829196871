import styled from 'styled-components';

const MyTitle = styled.div`
    font-weight: 400;
    font-size: 38px;
    text-transform: lowercase;
    color: #000;
    margin: 14px 0 12px 0;
`

export default MyTitle;