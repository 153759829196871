import React, { useState, useEffect, useRef } from 'react';
import ContentLoader from 'react-content-loader';
import Icons from '../../../../../Common/IconsComponent.jsx';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import { useGetEventLeadersMutation } from '../../../../../../services/phpService.js';
import MyTitle from '../../../../../Common/styles/MyTitle.jsx';
import './EventLeaders.scss';

const EventLeaders = ({ eventId }) => {
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [getEventLeaders] = useGetEventLeadersMutation();
	const { user, hidePopup } = usePopup();
	const containerRef = useRef(null);
	const [containerWidth, setContainerWidth] = useState(0);

	const id_telegram = user?.id_telegram;
	const id_event_gaming = eventId;

	useEffect(() => {
		console.log(eventId);
		if (user && eventId) {
			fetchData();
		}
	}, [user, eventId]);

	const fetchData = async () => {
		try {
			setIsLoading(true);
			const loadTimeStart = Date.now();

			const res = await getEventLeaders({ id_event_gaming, id_telegram });

			if (res?.data) {
				setLeaderboardData(res.data);
			}

			const loadTimeEnd = Date.now();
			const elapsedTime = loadTimeEnd - loadTimeStart;
			const remainingTime = 500 - elapsedTime;

			if (remainingTime > 0) {
				setTimeout(() => setIsLoading(false), remainingTime);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			console.error('Error fetching leaderboard data:', error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const updateContainerWidth = () => {
			if (containerRef.current) {
				setContainerWidth(containerRef.current.offsetWidth);
			}
		};
		updateContainerWidth();
		window.addEventListener('resize', updateContainerWidth);
		return () => {
			window.removeEventListener('resize', updateContainerWidth);
		};
	}, []);

	const numberOfRects = 12;
	const rects = Array.from({ length: numberOfRects }, (_, i) => (
		<rect key={i} x='0' y={i * 58} rx='16' ry='16' width={containerWidth} height='52' />
	));

	const closeModal = () => {
		hidePopup();
	};

	return (
		<div className='ratings' style={{height:'100%'}}>
			<div className='ratings__container container' style={{height:'100%',background: '#fff',
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%'}}>
				<div className='ratings__body' style={{height:'100%'}} ref={containerRef}>
					<div className='ratings__close' onClick={closeModal}>
						<Icons.Close />
					</div>
					<MyTitle>Leaderboard</MyTitle>
					{isLoading ? (
						<ContentLoader
							speed={2}
							width={'100%'}
							height={'100%'}
							viewBox={`0 0 ${containerWidth} 700`}
							backgroundColor='#f3f3f3'
							foregroundColor='#ecebeb'
						>
							{rects}
						</ContentLoader>
					) : (
						<ul className='ratings__list'>
							{leaderboardData.map((player, index) => {
								const isCurrentUser = player.username === user?.username;
								const isTopThree = index < 3;
								return (
									<li
										className={`ratings__listItem ${
											isCurrentUser && !isTopThree ? 'highlight' : ''
										}`}
										key={index}
									>
										<div className='ratings__itemData'>
											<div className='ratings__listId'>
												<Icons.PlayerId />
												<span>{player.position}</span>
											</div>
											<div className='ratings__listName'>
												<span>
													{player.username?.length > 10
														? `${player.username.substring(0, 10)}...`
														: player.username ?? 'anonymous'}
												</span>
											</div>
											<div className='ratings__listBalance'>
												<span>{player.total_votes}</span>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default EventLeaders;
