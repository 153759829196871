import { useState, useCallback } from 'react';
import { useGetProjectsMutation } from '../services/phpService';

const useFetchProjects = () => {
    const [projectsData, setProjectsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [getProjects] = useGetProjectsMutation();

    const fetchAllProjects = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const res = await getProjects();
            if (res?.data) {
                setProjectsData(res.data);
                localStorage.setItem('projectsData', JSON.stringify(res.data));
            } else {
                setProjectsData(null);
                localStorage.removeItem('projectsData'); 
            }
        } catch (err) {
            console.error('Error fetching projects data:', err);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [getProjects]);

    return { projectsData, fetchAllProjects, loading, error };
};

export default useFetchProjects;
