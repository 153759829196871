import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MyButton } from '../Common/styles';

const ErrorhScreen = () => {
	const navigate = useNavigate();

	const navBack = () => {
		navigate(`/`);
	};
	return (
		<div className='error_404'>
			<img className='img_404' src='/404.svg' alt='404' />
			<img className='img_404_face' src='/404_face.svg' alt='face' />
			<p className='p_not_found'>not found</p>
			<div className='d_404_desc'>
				We’re sorry, the page you requested cloud was not found.
			</div>
			<MyButton className='mainBtn' onClick={() => navBack()}>back</MyButton>
		</div>
	);
};
export default ErrorhScreen;
