export const animateDoneTask = (taskEl) =>
	{
        if (!taskEl) return;
		const newTasks = taskEl.current.closest('.tasks__list').querySelector('.waiting');
		if (newTasks)
			{
				setTimeout(() => {
					taskEl.current.closest('.tasks__item').classList.add('animate__animated','animate__zoomOut');
					setTimeout(() => {
						taskEl.current.closest('.tasks__item').classList.add('hidden');
						newTasks.classList.remove('waiting');
						newTasks.classList.add('animate__animated', 'animate__backInUp');
						
					}, 500);
				}, 500);
			}
	}