import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'animate.css';

const Notification = ({ type, message, onClose, isVisible }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (isVisible) {
            ref.current.classList.add('animate__backInDown');
            ref.current.classList.remove('animate__backOutUp');
        } else {
            ref.current.classList.add('animate__backOutUp');
            ref.current.classList.remove('animate__backInDown');
        }
    }, [isVisible]);

    const handleAnimationEnd = () => {
        if (!isVisible) {
            onClose();
        }
    };

    return (
        <div
            ref={ref}
            className={`my-notification ${type} animate__animated`}
            onAnimationEnd={handleAnimationEnd}
            onClick={() => {onClose();}}
        >
            <span>{message}</span>
            <img className='tail' src="/tail.png" alt="tail" />
        </div>
    );
};

Notification.propTypes = {
    type: PropTypes.oneOf(['success', 'error', 'info']).isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
};

export default Notification;
