// components/Notification/NotificationsContainer.js
import React from 'react';
import { usePopup } from '../Popups/PopupContext';
import Notification from './Notification';

const NotificationsContainer = () => {
    const { notifications, removeNotification } = usePopup();

    return (
        <div className="notifications-container">
            {notifications.map((notification) => (
                <Notification
                    key={notification.id}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => removeNotification(notification.id)}
                    isVisible={notification.isVisible}
                />
            ))}
        </div>
    );
};

export default NotificationsContainer;
