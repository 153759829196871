import { useState, useCallback } from 'react';

const useNotifications = () => {
    const [notifications, setNotifications] = useState([]);

    
    const addNotification = useCallback((type, message) => {
        const id = new Date().getTime();
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            { id, type, message, isVisible: true },
        ]);

        setTimeout(() => {
            hideNotification(id);
        }, 3200);
    }, []);

    const hideNotification = useCallback((id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
                notification.id === id
                    ? { ...notification, isVisible: false }
                    : notification
            )
        );
    }, []);

    const removeNotification = useCallback((id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
        );
    }, []);

    return { notifications, addNotification, removeNotification, hideNotification };
};

export default useNotifications;
