import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClaimerWidget from './Claimer/ClaimerWidget.jsx';
import HeroComponent from './HeroComponent.jsx';
import NavigationWidget from './NavigationWidget.jsx';
import BalanceComponent from './BalanceComponent.jsx';
import QRscreen from '../QRscreen/QRscreen.jsx';
import { usePopup } from '../../Popups/PopupContext.js';
import TaskList from './Tasks/TaskList.jsx';
// import useSaveAction from '../../../helpers/analyticsHelper';
import Preloader from './Preloader/Preloader.jsx';
import { MyButton } from '../../Common/styles';

const HomeScreen = () => {
	const navigate = useNavigate();
	const { user, showPopup } = usePopup();
	// const { saveAction } = useSaveAction();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!user) {
			navigate('/');
		}
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 750);

		return () => clearTimeout(timer);
	}, [user, navigate]);

	const isMobileDevice =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);

	const handleTasksClick = () => {
		showPopup(<TaskList />);
		// saveAction(user?.id_telegram, 'click_main_tasks', user?.parent_id);
	};

	if (isLoading) {
		return <Preloader />;
	}

	return (
		<div className='main main__body'>
			{!isMobileDevice ? (
				<QRscreen />
			) : (
				<>
					<BalanceComponent visible={true} title={'Your $hit balance:'} />
					<HeroComponent />
					<ClaimerWidget />

					<MyButton variant='black' className='mainBtn' onClick={handleTasksClick}>
						tasks
					</MyButton>

					<NavigationWidget />
				</>
			)}
		</div>
	);
};

export default HomeScreen;
