// Button.jsx
import styled from 'styled-components';

// Определение глобального стиля кнопки
const StyledButton = styled.button`
  border: 2px solid #000;
  border-radius: 100px;
  color: var(--black);
  padding: 10px 23px;
  box-shadow: 7px 7px 0 #000;
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--white);

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  @media (any-hover: hover) {
    &:hover {
      transform: translate(7px, 7px);
      box-shadow: 0 0 0 #000;
    }
  }

  &:active,
  &.active {
    transform: translate(7px, 7px);
    box-shadow: 0 0 0 #000;
  }

  &.hover {
    transform: translate(7px, 7px);
    box-shadow: 0 0 0 #000;
  }
`;

// Дополнительные стили для вариаций
const StyledButtonBlack = styled(StyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 23px;
  border-radius: 60px;
  color: var(--white);
  background: var(--black);
  box-shadow: 5px 5px 0 #fff, 5px 5px 0 1.5px #000;
  transition: all 0.1s linear;
  background-position: 20px center;

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  @media (any-hover: hover) {
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: 0 0 0 #fff, 0 0 0 0 #000;
    }
  }

  &.hover {
    transform: translate(5px, 5px);
    box-shadow: 0 0 0 #fff, 0 0 0 0 #000;
  }
`;

const StyledButtonInactive = styled(StyledButton)`
  font-size: 28px;
  background: var(--gray);
  color: var(--black);
  pointer-events: none;
  width: 100%;
  max-width: 301px;
  position: relative;
  margin-top: 10px;
  box-shadow: none;
  border:none!important;
  svg {
    width: 24px;
    height: 24px;
    fill: var(--black);
  }
`;

const StyledButtonSmall = styled(StyledButton)`
box-shadow: 3px 3px 0 #000;
@media (any-hover: hover) {
  &:hover {
    transform: translate(3px, 3px);
    box-shadow: 0 0 0 #000;
  }
}

&:active,
&.active {
  transform: translate(3px, 3px);
  box-shadow: 0 0 0 #000;
}

&.hover {
  transform: translate(3px, 3px);
  box-shadow: 0 0 0 #000;
}
`;


const StyledButtonSmallIcon = styled(StyledButtonSmall)`
  position: absolute;
  z-index: 100;
  width: 44px;
  height: 48px;
`;

const StyledButtonTab = styled(StyledButtonSmall)`
  min-width: 130px;
  white-space: nowrap;

  &.active {
      background: var(--black);
      color: var(--white);
      box-shadow: none;
  }

  &:not(:last-child) {
      margin-right: 8px;
  }
`;


const MyButton = ({ variant = 'default', ...props }) => {
  if (variant === 'black') return <StyledButtonBlack {...props} />;
  if (variant === 'inactive') return <StyledButtonInactive {...props} />;
  if (variant === 'small') return <StyledButtonSmall {...props} />;
  if (variant === 'smallIcon') return <StyledButtonSmallIcon {...props} />;
  if (variant === 'smallTab') return <StyledButtonTab {...props} />;
  return <StyledButton {...props} />;
};

export default MyButton;
