import styled from 'styled-components';

const MyAnnounce = styled.div`
    background: #000;
    border-radius: 24px;
    font-weight: 400;
    color: #fff;
    padding: 15px 22px 19px 22px;
    height: 96px;
    display: flex;
    gap: 19px;
    align-items: center;
    justify-content: space-between;
    h2 {
        font-size: 28px;
    }
    p {
        font-size: 18px;
    }
    svg {
        width: 13px;
        height: 12px;
        margin-left: 6px;
    }
    button {
        height: 38px;
        padding: 0 14px;
        background: #fff500;
        color: #000;
        border-radius: 44px;
        display: flex;
        align-items: center;
        font-size: 22px;
    }
`

export default MyAnnounce;