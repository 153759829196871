import { useState, useEffect } from 'react';

const useBoost = (gamePaused) => {
  const positions = [
    { x: 100, y: -250 },
    { x: 100, y: -200 },
    { x: 100, y: -150 },
    { x: 100, y: -100 },
    { x: 100, y: -50 },
    { x: 100, y: 0 },
    { x: -50, y: -250 },
    { x: -50, y: -200 },
    { x: -50, y: -150 },
    { x: -50, y: -100 },
    { x: -50, y: -50 },
    { x: -50, y: 0 },
    { x: 210, y: -250 },
    { x: 210, y: -200 },
    { x: 210, y: -150 },
    { x: 210, y: -100 },
    { x: 210, y: -50 },
    { x: 210, y: 0 },
  ];

  const [boostPhase, setBoostPhase] = useState(false);
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const randomizePosition = () => {
    const randomIndex = Math.floor(Math.random() * positions.length);
    const { x, y } = positions[randomIndex];
    setPosition({ x, y });
  };

  useEffect(() => {
    let showBoostTimeout;
    let hideBoostTimeout;

    if (!gamePaused) {
      if (!visible) {
        randomizePosition();
        showBoostTimeout = setTimeout(() => {
          randomizePosition();
          setVisible(true);
        }, 15000);
      } else {
        hideBoostTimeout = setTimeout(() => {
          setVisible(false);
        }, 8000);
      }
    }

    return () => {
      clearTimeout(showBoostTimeout);
      clearTimeout(hideBoostTimeout);
    };
  }, [visible, gamePaused]);

  const activateBoost = (energyVal, clickNewCoins, setEnergyVal, setClickNewCoins, user) => {
    const prevEnergyVal = energyVal;
    const prevClickNewCoins = clickNewCoins;

    setBoostPhase(true);
    setVisible(false);
    setEnergyVal(user?.tap_boost_value ?? 4);
    setClickNewCoins(user?.tap_boost_value ?? 4);

    setTimeout(() => {
      setEnergyVal(prevEnergyVal);
      setClickNewCoins(prevClickNewCoins);
      setBoostPhase(false);
      setVisible(false);
    }, 10000);
  };

  return {
    boostPhase,
    visible,
    position,
    activateBoost,
  };
};

export default useBoost;
