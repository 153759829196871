import React, { useEffect, useState } from 'react';
import Icons from '../../Common/IconsComponent';
import { usePopup } from '../../Popups/PopupContext.js';
import MyBalance from '../../Common/styles/MyBalance';

const BalanceComponent = ({ visible, title }) => {
	const { user, setUserDataPollingDisabled } = usePopup();
	const [balance, setBalance] = useState(0);

	useEffect(() => {
		if (user?.wallet_balance && user.wallet_balance != balance) {
			setUserDataPollingDisabled(false);
			setBalance(user.wallet_balance);
		}
	}, [user]);

	return (
		<MyBalance>
			{visible && (
				<>
					<span>{title}</span>
					<div>
						{balance}
						<Icons.Shit />
					</div>
				</>
			)}
		</MyBalance>
	);
};
export default BalanceComponent;
