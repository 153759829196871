import React from 'react';
import heroAnimation from '../../../../assets/animations/potato.riv';
import Icons from '../../../Common/IconsComponent.jsx';

import { useRive } from '@rive-app/react-canvas';
import { motion } from 'framer-motion'; 


const Preloader = () => {
    const { RiveComponent, rive } = useRive({
        src: heroAnimation, 
        autoplay: true, 
    });

    return (
        <div className='preloader active'>
					<div className='preloader__body'>
						{/*<h2 className='preloader__title'>Please wait...</h2>*/}
						<div className='preloader__bar'>
							<div className='preloader__image'>
								{/* <img src={imgPreloader} alt='The $hit' /> */}
                                
                                <motion.div
                            className='spinner' 
                            style={{
                                border: '4px solid rgba(0, 0, 0, 0)', 
                                borderTop: '6px solid #000', 
                                borderLeft: '6px solid #000', 
                                borderRadius: '50%',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                            }}
                            animate={{ rotate: 360 }} 
                            transition={{
                                repeat: Infinity, 
                                ease: "linear",
                                duration: 2,
                            }}
                        />
                                
								<RiveComponent style={{ display: 'none' }} />
								<div className='preloader_image_in'></div>

                                

							</div>
							<Icons.Loader />
						</div>
						<div className='preloader__percent'>{/* <span>0</span>% */}</div>
						{/* <h1 className='preloader__app_title'>the $hit</h1>
						<span className='preloader__app_subtitle'>vote and earn</span> */}
					</div>
				</div>
    )
}

export default Preloader;