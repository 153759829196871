import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useGetUserByTgIdQuery } from '../../../services/phpService';
import { usePopup } from '../../Popups/PopupContext.js';
import { useRive } from '@rive-app/react-canvas';
import heroAnimation from '../../../assets/animations/potato.riv';
import WebFont from 'webfontloader';
import QRscreen from '../QRscreen/QRscreen.jsx';
import Preloader from '../Home/Preloader/Preloader';

const SplashScreen = () => {
	const { login, setUser, addNotification, isAuthenticated } = usePopup();
	const tg = window.Telegram.WebApp;
	const initData = tg.initDataUnsafe;
	const isDevMode = process.env.NODE_ENV === 'development';
	const isLocalhost = window.location.hostname === 'localhost';
	const [fontsLoaded, setFontsLoaded] = useState(false);
	const [starting_project_id, setProjectId] = useState(0); // project ID to open on start
	const [starting_event_id, setEventId] = useState(0); // event ID to open on start
	const [userFetchAttempts, setUserFetchAttempts] = useState(0);
	const secretURL = process.env.REACT_APP_REGISTER_KEY;
	const navigate = useNavigate();

	const isMobileDevice =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	// const isMobileDevice = true;

	let userId;
	let processAuth;

	if (isDevMode && isLocalhost) {
		userId = 400815946; //7453951668;//321967834; //7453951668; // 400815946 // 64288442 // 123456789842 // 321967834
		processAuth = true;
	} else {
		userId = initData?.user?.id;
		processAuth = !!initData?.user?.id;
	}
	if (userId) {
		localStorage.setItem('userID', userId);
	}

	const [skip, setSkip] = useState(true);
	const {
		data: userData,
		isLoading,
		isError,
		error,
	} = useGetUserByTgIdQuery(userId, {
		skip: skip,
		pollingInterval: 2000,
	});

	// Check fonts
	useEffect(() => {
		WebFont.load({
			custom: {
				families: ['Lacquer'],
				urls: ['/fonts.css'],
			},
			active: () => {
				setFontsLoaded(true);
			},
		});
	}, []);

	useEffect(() => {
		if (processAuth) {
			setSkip(false);
		} else {
			addNotification('info', 'Please run application in Telegram App');
			setSkip(true);
		}
	}, []);

	const parseStartParam = (startParam) => {
		let parent_user_telegram_id = null;
		let project_id = null;
		let event_id = null;

		// Check if the start_param contains both 'u' (user), 'p' (project), and 'e' (event)
		if (/u-?\d+p\d+e\d+/.test(startParam)) {
			const userIdMatch = startParam.match(/u(-?\d+)/);
			const projectIdMatch = startParam.match(/p(\d+)/);
			const eventIdMatch = startParam.match(/e(\d+)/);
			parent_user_telegram_id = userIdMatch ? userIdMatch[1] : null;
			project_id = projectIdMatch ? projectIdMatch[1] : null;
			event_id = eventIdMatch ? eventIdMatch[1] : null;
		}
		// Check if the start_param contains 'u' (user) and 'p' (project)
		else if (/u-?\d+p\d+/.test(startParam)) {
			const userIdMatch = startParam.match(/u(-?\d+)/);
			const projectIdMatch = startParam.match(/p(\d+)/);
			parent_user_telegram_id = userIdMatch ? userIdMatch[1] : null;
			project_id = projectIdMatch ? projectIdMatch[1] : null;
		}
		// Check if the start_param contains 'u' (user) and 'e' (event)
		else if (/u-?\d+e\d+/.test(startParam)) {
			const userIdMatch = startParam.match(/u(-?\d+)/);
			const eventIdMatch = startParam.match(/e(\d+)/);
			parent_user_telegram_id = userIdMatch ? userIdMatch[1] : null;
			event_id = eventIdMatch ? eventIdMatch[1] : null;
		}
		// Check if the start_param contains only 'u' (user)
		else if (/u-?\d+/.test(startParam)) {
			const userIdMatch = startParam.match(/u(-?\d+)/);
			parent_user_telegram_id = userIdMatch ? userIdMatch[1] : null;
		}
		// Check if the start_param contains only 'p' (project)
		else if (/p\d+/.test(startParam)) {
			const projectIdMatch = startParam.match(/p(\d+)/);
			project_id = projectIdMatch ? projectIdMatch[1] : null;
		}
		// Check if the start_param contains only 'e' (event)
		else if (/e\d+/.test(startParam)) {
			const eventIdMatch = startParam.match(/e(\d+)/);
			event_id = eventIdMatch ? eventIdMatch[1] : null;
		}
		// Otherwise, assume the start_param contains only the user_id
		else if (/^-?\d+$/.test(startParam)) {
			parent_user_telegram_id = startParam;
		}

		return { parent_user_telegram_id, project_id, event_id };
	};

	const navigateToStartScreen = (project_id, event_id) => {
		const queryParams = new URLSearchParams(window.location.search);
		const redirectParam = queryParams.get('r');

		if (event_id && event_id !== '0') {
			navigate('/events/' + event_id);
		} else if (project_id) {
			navigate('/projects/' + project_id);
		} else if (redirectParam) {
			navigate(redirectParam);
		} else {
			navigate('/home');
		}
	};

	useEffect(() => {
		const { parent_user_telegram_id, project_id, event_id } = parseStartParam(
			initData?.start_param
		);
		// alert ('Referral params check \nParent user id: '+parent_user_telegram_id+'\nProject_id: '+project_id);
		setProjectId(project_id);
		setEventId(event_id);
		const registerUser = async () => {
			try {
				const body = {
					query_id: initData?.query_id,
					user: {
						id: initData?.user?.id,
						is_bot: initData?.user?.is_bot,
						is_premium: initData?.user?.is_premium,
						first_name: initData?.user?.first_name,
						last_name: initData?.user?.last_name,
						username: initData?.user?.username,
						language_code: initData?.user?.language_code,
					},
					auth_date: initData?.auth_date,
					hash: initData?.hash,
					timezone: moment.tz.guess(), // Automatically get the user's timezone
				};

				//alert ('Registering user \nParent user id: '+parent_user_telegram_id+'\nProject_id: '+project_id);
				// Conditionally add parent_id_telegram
				if (parent_user_telegram_id) {
					body.parent_id_telegram = parent_user_telegram_id;
				}

				// Adding project id that was spesified in refferal link
				if (project_id) {
					body.referral_project_id = project_id;
				}

				if (event_id) {
					body.referral_event_id = event_id;
				}

				const response = await fetch(`${secretURL}/register`, {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(body),
				});

				if (!response.ok) {
					// if user exists - getting info from php backend
					if (response.status == 409) {
						setSkip(false); // settign polling skip to false
					} else {
						setSkip(true);
						addNotification(
							'error',
							'Oops! Something went wrong. Please try again later!'
						);
						throw new Error('Network response was not ok');
					}
				} else setSkip(false);
			} catch (error) {
				console.error('Error:', error);
			}
		};

		if (!isLoading && processAuth) {
			setUserFetchAttempts(userFetchAttempts + 1);

			if (userFetchAttempts > 3) {
				setSkip(true);
				addNotification('error', 'Oops! Something went wrong. Please try again later!');
				return;
			}

			if (isError) {
				if (error.status == 404) {
					if (isDevMode) console.log('registering user');
					registerUser();
				} else {
					addNotification('error', 'Oops! Something went wrong. Please try again later!');
					setSkip(true);
				}
				return;
			}

			if (userData && userData?.id_telegram) {
				setUser(userData);
				login();
			}
		}
	}, [initData, userData, secretURL, isLoading, error]);

	const [animationLoaded, setAnimationLoaded] = useState(false);
	const { RiveComponent } = useRive({
		src: heroAnimation,
		onLoad: () => {
			setAnimationLoaded(true);
		},
	});

	const starting_project_id_ref = useRef(starting_project_id);
	const starting_event_id_ref = useRef(starting_event_id);

	useEffect(() => {
		starting_project_id_ref.current = starting_project_id;
	}, [starting_project_id]);

	useEffect(() => {
		starting_event_id_ref.current = starting_event_id;
	}, [starting_event_id]);

	useEffect(() => {
		if (isAuthenticated) {
			navigateToStartScreen(starting_project_id, starting_event_id);
		}
	}, [isAuthenticated, starting_project_id, starting_event_id]);

	return (
		<div className='preloader-container'>
			{!isMobileDevice ? <QRscreen /> : <Preloader />}
		</div>
	);
};

export default SplashScreen;
